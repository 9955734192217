<template>
  <div>
    <b-overlay
      opacity="0.5"
      :show="events_overlay"
      spinner-variant="success"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div v-if="event_type == 1">
        <b-button
          style="margin-bottom: 1rem"
          variant="success"
          @click="exportToJsonFile(new_data)"
        >
          <feather-icon icon="PlayIcon" class="mr-50" />
          <span class="align-middle">JSON</span>
        </b-button>
        <b-table
          responsive="sm"
          :items="events"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(actions)="data">
            <div>
              <b-button
                v-if="data.item.visto == 0"
                variant="flat-success"
                class="btn-icon"
                @click="check_event(data.item.id)"
                v-b-tooltip.hover.v-success
                title="Marcar como visto"
              >
                <feather-icon icon="CheckCircleIcon" />
              </b-button>
            </div>
          </template>

          <template #cell(message)="data">
            {{
              possible_conditions.find(
                (find_data) => find_data.id == data.item.condicoId
              ).message
            }}
          </template>

          <template #cell(deviceId)="data">
            {{
              possible_devices.find(
                (find_data) => find_data.id == data.item.deviceId
              ).name
            }}
          </template>

          <template #cell(variable)="data">
            {{
              get_variables.find(
                (variable) =>
                  variable.id ==
                  possible_conditions
                    .find((find_data) => find_data.id == data.item.condicoId)
                    .rules.split("variableId:", 2)[1]
                    .charAt(1)
              ).name
            }}
          </template>

          <template #cell(value)="data" v-if="all_datas.length != 0">
            {{ data.item.dadoId }}
          </template>

          <template #cell(created)="data">
            {{ new Date(data.item.createdAt).toLocaleString() }}
          </template>

          <template #cell(stats)="data">
            <b-badge :variant="possible_colors[data.item.visto].color">
              {{ possible_colors[data.item.visto].check }}
            </b-badge>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="
            this.events.filter(
              (data) => data.groupId == $store.state.active_group.id
            ).length
          "
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
      <div v-if="event_type == 0">
        <b-row>
          <b-col md="6" style="min-height: 30rem">
            <Mapbox v-if="ready" style="width: 140%" event_mode="true">
            </Mapbox>
          </b-col>
          <b-col md="6" style="margin-top: 10rem">
            <b-table
              v-if="ready"
              ref="selectableTable"
              selectable
              select-mode="multi"
              responsive="sm"
              :items="area_events"
              :fields="map_fields"
              :per-page="perPage"
              :current-page="currentPage"
              @row-selected="onRowSelected"
            >
              <template #cell(actions)="data">
                <div>
                  <b-button
                    v-if="data.item.visto == 0"
                    variant="flat-success"
                    class="btn-icon"
                    @click="check_event(data.item.id)"
                    v-b-tooltip.hover.v-success
                    title="Marcar como visto"
                  >
                    <feather-icon icon="CheckCircleIcon" />
                  </b-button>
                </div>
              </template>

              <template #cell(start_valueTime)="data">
                {{
                  new Date(data.item.start_valueTime * 1000).toLocaleString()
                }}
              </template>

              <template #cell(end_valueTime)="data">
                {{ new Date(data.item.end_valueTime * 1000).toLocaleString() }}
              </template>
            </b-table>
            <b-pagination
              v-if="ready"
              v-model="currentPage"
              :total-rows="area_events.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <h3 v-if="event_type == 2">Não há eventos</h3>
    </b-overlay>
  </div>
</template>

<script>
import { BTable, BOverlay } from "bootstrap-vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BPagination,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import axios from "axios";
import Cookies from "js-cookie";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Mapbox from "../layouts/components/MapEvents.vue";
import EventBus from "@/store/eventBus";
import api from "../service/api.js";
import eventBus from "@/store/eventBus";

export default {
  components: {
    Mapbox,
    VBTooltip,
    BBadge,
    BButton,
    BPagination,
    BTable,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BOverlay,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }
    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }
    store.state.wall_points = [];
    this.initial_requests();
  },

  data() {
    return {
      events_overlay: true,
      ordered_datas: [],
      area_events: [],
      ready: false,
      event_type: 2,
      all_datas: [],
      my_conditions: [],
      selected: [],
      devices_requests: "",
      get_variables: [],
      possible_devices: [],
      possible_conditions: [],
      events: [],
      perPage: 6,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        { key: "deviceId", label: "Dispositivo", sortable: false },

        { key: "message", label: "Mensagem", sortable: false },

        { key: "variable", label: "Variável", sortable: false },

        { key: "value", label: "DadoID", sortable: false },

        { key: "stats", label: "Status", sortable: false },

        { key: "created", label: "Criado em", sortable: false },

        { key: "actions", label: "Ação", sortable: false },
      ],

      map_fields: [
        { key: "start_valueTime", label: "Inicio do evento", sortable: false },

        { key: "end_valueTime", label: "Fim do evento", sortable: false },
      ],
      possible_colors: [
        {
          id: 0,
          color: "light-danger",
          check: "Não visto",
        },
        {
          id: 1,
          color: "light-success",
          check: "Visto",
        },
      ],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    async initial_requests() {
      let self = this;
      self.get_variables = await api.get_variables();
      let possible_events = await api.get_events();
      self.events = possible_events.filter(
        (filter_data) => filter_data.condicoId == store.state.selected_condition
      );

      self.my_conditions = await api.get_conditions_device();

      self.possible_conditions = await api.get_conditions();

      self.possible_devices = await api.get_devices();

      let the_condition = this.possible_conditions
        .find((find_data) => find_data.id == store.state.selected_condition)
        .rules.split("sinal:", 2)[1]
        .charAt(1);

      if (the_condition != 6 && the_condition != 7) {
        this.event_type = 1;
        this.events_overlay = false;
      } else {
        this.event_type = 0;

        // Formatando as coordenadas
        let a = String(
          this.possible_conditions
            .find((find_data) => find_data.id == store.state.selected_condition)
            .rules.split("grid: [[", 2)[1]
        ).slice(0, -3);
        let b = a.split("],[", 5000);
        let c = [];
        b.forEach((data, index) => {
          c[index] = "";
          c[index] = [data.split(",", 3)[0], data.split(",", 3)[1]];
        });
        // c = objeto com as coordenadas latitude e longitude dos pontos da cerca
        store.state.wall_points = c;

        this.map_coords();
      }
    },

    async map_coords() {
      let all_devices = [];
      let my_cont = 0;
      var self = this;
      this.my_conditions.forEach((data, index) => {
        all_devices[index] = data.deviceId;
      });
      let all_devices_n = all_devices.reduce(function(a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);
      let device_list = "";
      all_devices_n.forEach((data) => {
        device_list += "&deviceId=" + data;
      });

      // let variable_params = "&variableId=1&variableId=2";
      this.all_datas = await api.getAllDatas(
        device_list
      );
      console.log("this.all_datas é: ", this.all_datas)
      // Inicio da comparação entrou e saiu da área
      let events_data = [];
      self.events.forEach((data, index) => {
        events_data[index] = self.all_datas.find((e) => e.id == data.dadoId);
      });
      let ordered_events_data = events_data.sort(
        (a, b) => a.valueTime - b.valueTime
      );
      console.log("Ordered_events_data é ", ordered_events_data)
      let filtered_datas = self.all_datas.filter(
        (e) => e.valueTime >= ordered_events_data[0].valueTime
      );
      this.ordered_datas = filtered_datas.sort(
        (a, b) => a.valueTime - b.valueTime
      );
      self.area_events = [];
      let last_index = ordered_events_data.length - 2;
      ordered_events_data.forEach((data, index) => {
        if (index == ordered_events_data.length - 1) {
          self.area_events.push({
            start_valueTime: ordered_events_data[last_index + 1].valueTime,
            end_valueTime: data.valueTime,
          });
        } else {

          if (ordered_events_data[index + 1].valueTime - data.valueTime > 500) {
            // Delay entre cada evento, caso maior que 500, é considerado como outro momento
            if (self.area_events.length == 0) {
              self.area_events.push({
                start_valueTime: ordered_events_data[0].valueTime,
                end_valueTime: ordered_events_data[index - 1].valueTime,
              });
              last_index = index;
            } else {

              self.area_events.push({
                start_valueTime: ordered_events_data[last_index + 1].valueTime,
                end_valueTime: data.valueTime,
              });
            }
          }
        }
      });
      self.ready = true;
      this.events_overlay = false;
    },

    async check_event(event) {
      const headers = {
        Authorization: this.$store.state.token_api,
      };

      let data = {
        id: this.events.find((find_data) => find_data.id == event).id,
        groupId: store.state.active_group.id,
        visto: 1,
      };

      var self = this;
      await axios
        .patch("https://tekie.cloud:3031/eventos", data, { headers })

        .then(function(response) {
          //"Success! = ", response);
          self.initial_requests();
        })
        .catch(function(e) {
          //" Erro ao tentar editar evento: ",e);
        });
      EventBus.$emit("patch_event");
    },

    exportToJsonFile(jsonData) {
      let dataStr = JSON.stringify(jsonData);
      let dataUri =
        "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

      let exportFileDefaultName = "data.json";

      let linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataUri);
      linkElement.setAttribute("download", exportFileDefaultName);
      linkElement.click();
    },
  },
  watch: {
    selected: function() {
      let self = this;
      if (self.selected.length == 0) {
        eventBus.$emit("delete_heat_map");
      } else {
        let filtered_data = self.ordered_datas.filter(
          (e) =>
            e.valueTime >= self.selected[0].start_valueTime &&
            e.valueTime <= self.selected[0].end_valueTime
        );
        let all_latitude = filtered_data.filter((e) => e.variableId == 1);
        let all_longitude = filtered_data.filter((e) => e.variableId == 2);
        let coords_data = [];
        if (all_latitude.length == all_longitude.length) {
          all_latitude.forEach((element, index) => {
            coords_data[index] = [all_longitude[index].value, element.value];
          });

          EventBus.$emit("coords", coords_data);
        } else {
          console.log(
            "N° de coordenadas do tipo latiude são diferentes do n° de longitudes, verifique os dados"
          );
          console.log("Filtered_data é: ", filtered_data);
          console.log("All_Latiude é: ", all_latitude);
          console.log("All Longitude é: ", all_longitude);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
.table.b-table > tbody > .table-active,
[dir] .table.b-table > tbody > .table-active > th,
[dir] .table.b-table > tbody > .table-active > td {
  background-color: #bbb6b62e !important;
}
</style>
