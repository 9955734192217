<template>
  <b-row style="height: 100%">
    <b-col md="9" style="height: 100%; margin-bottom: 2rem">
      <b-card class="mb-8" style="height: 100%; width: 100%">
        <div id="map-container" style="width: 100%; height: 100%">
          <MglMap
            container="map-container"
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="center"
            :zoom="zoom"
            @load="onMapLoaded"
          >
            <!-- MglMarker para ver eventos mapa -->
            <div>
              <MglMarker
                color="#00D455"
                v-for="(item, index) in points"
                :key="'MakerId_' + index + points.length + cont"
                :coordinates="points[index]"
              >
                <MglPopup>
                  As coordenadas são: {{ item[0] }},{{ item[1] }}
                  <div class="demo-inline-spacing">
                    <b-avatar
                      variant="light-dark"
                      style="margin-right: 0.5rem; margin-top: 0rem"
                    >
                      <feather-icon
                        :icon="
                          possible_icons[
                            devices.find((find_data) => find_data.id == item[2])
                              .icon
                          ].icon
                        "
                        size="20rem"
                      />
                    </b-avatar>
                    <div style="margin-top: 0rem">
                      <h5 class="mb-0">
                        {{
                          devices.find((find_data) => find_data.id == item[2])
                            .name
                        }}
                      </h5>
                      <small class="text-muted">
                        #
                        {{
                          devices
                            .find((find_data) => find_data.id == item[2])
                            .createdAt.split("+", 1)[0]
                        }}
                      </small>
                    </div>
                  </div>
                </MglPopup>
              </MglMarker>
            </div>

            <MglNavigationControl position="top-right" />
          </MglMap>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BCardText,
  BFormRadio,
  BButton,
  BFormSpinbutton,
} from "bootstrap-vue";
import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglNavigationControl, MglPopup } from "v-mapbox";
import axios from "axios";
import store from "@/store/index";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import EventBus from "@/store/eventBus";
import api from "../../service/api.js";

export default {
  components: {
    BFormSpinbutton,
    BFormCheckbox,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormRadio,
    MglMap,
    MglMarker,
    BButton,
    MglNavigationControl,
    MglPopup,
    MapboxDraw,
  },

  data() {
    return {
      heat_data: {
        type: "FeatureCollection",
        crs: {
          type: "name",
        },
        features: [],
      },
      wall: [],
      points: [],
      cont: 0,
      coordinates: [],
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
          selected: "flat-success",
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
          selected: "flat-darken-2",
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
          selected: "flat-darken-2",
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
          selected: "flat-darken-2",
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
          selected: "flat-darken-2",
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
          selected: "flat-darken-2",
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
          selected: "flat-darken-2",
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
          selected: "flat-darken-2",
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
          selected: "flat-darken-2",
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
          selected: "flat-darken-2",
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
          selected: "flat-darken-2",
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
          selected: "flat-darken-2",
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
          selected: "flat-darken-2",
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
          selected: "flat-darken-2",
        },
      ],
      accessToken:
        "pk.eyJ1IjoiZHRpYWdvIiwiYSI6ImNrYzB4b2JlODE4azMyc3U2dnJ0M3RzdmIifQ.JjLKI9mfpEot4J2FNEtNOQ",
      mapStyle: "mapbox://styles/mapbox/satellite-v9",
      center: [-46.9397181, -19.5933794],
      coordinatesMarker: [],
      coordinatesToGo: [0, 0],
      zoom: 4,
      devices: [],
    };
  },
  async created() {
    var self = this;
    this.mapbox = Mapbox;

    EventBus.$on("coords", function(data) {
      self.coords(data);
    });

    EventBus.$on("delete_heat_map", () => {
      let self = this;
      var mapLayer = self.map.getLayer("earthquakes-heat");
      if (typeof mapLayer !== "undefined") {
        self.map.removeLayer("earthquakes-heat").removeSource("earthquakes");
      }
    });
    
  },
  methods: {
    coords(data) {
      data.forEach((element) => {
        this.heat_data.features.push({
          type: "Feature",
          properties: {
            id: "ak16994521",
            mag: 1,
            time: 1507425650893,
            felt: null,
            tsunami: 0,
          },
          geometry: {
            type: "Point",
            coordinates: [element[0], element[1], 0],
          },
        });
      });

      this.map.addSource("earthquakes", {
        type: "geojson",
        data: this.heat_data,
      });

      this.map.addLayer(
        {
          id: "earthquakes-heat",
          type: "heatmap",
          source: "earthquakes",
          maxzoom: 24,
          paint: {
            "heatmap-radius": 8,
            "heatmap-opacity": 0.5,
            // increase weight as diameter breast height increases
            "heatmap-weight": {
              property: "dbh",
              type: "exponential",
              stops: [
                [1, 0],
                [62, 1],
              ],
            },
            // increase intensity as zoom level increases
            "heatmap-intensity": 2,
          },
        },
      );
    },

    async onMapLoaded(event) {
      console.log("Mapa carregado!");
      this.wall = store.state.wall_points;

      this.map = event.map;
      this.$store.map = event.map;
      this.map.resize();
      this.asyncActions = event.component.actions;

      await axios
        .get("https://tekie.cloud:3031/devices", {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })
        .then((response) => {
          this.devices = response.data.data;
        })
        .catch((e) => {});

      let data = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: "",
            },
          },
        ],
      };
      data.features[0].geometry.coordinates = [this.wall[0]];
      this.map.addSource("trace", { type: "geojson", data: data });

      this.map.addLayer({
        id: "trace",
        type: "line",
        source: "trace",
        paint: {
          "line-color": "yellow",
          "line-opacity": 0.9,
          "line-width": 3,
        },
      });

      this.map.jumpTo({ center: this.wall[0], zoom: 14 });
      this.map.setPitch(20);
      for (let i = 0; i < this.wall.length; i++) {
        data.features[0].geometry.coordinates.push(this.wall[i]);
        this.map.getSource("trace").setData(data);
        this.map.panTo(this.wall[i]);
      }
    },
  },
};
</script>

<style>
.page-item.active .page-link {
  background-color: #ff4804;
}
</style>
